import * as React from 'react';

import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Seo from '../components/seo';
import Layout from '../components/layout';

const TechTalks = (queryData) => {
  const {
    data:
    {
      contentfulBlogPost:
    {
      title,
      postAuthor,
      postImage,
      postAuthorImage,
      body,
    },
      file:
    {
      childImageSharp,
    },
    },
  } = queryData;

  const blogData = body.childMarkdownRemark.html;

  return (
    <Layout>
      <div className="post-content container mx-auto mb-5 font-futura text-1.125rem pt-[10rem] md:pt-14rem">
        <Seo title="Tech Talks" />
        <div className="max-w-3xl m-auto">
          <h1 className="mb-6 text-30px font-semibold leading-md">{title}</h1>
          <div className="flex items-center mb-md">
            <GatsbyImage className="rounded-full w-8 h-8" image={postAuthorImage.gatsbyImageData} alt="post image" />
            <h4 className="leading-md text-center align-center mb-0 ml-75rem text-1.125rem">{postAuthor}</h4>
            <div className="w-5rem h-5rem ml-75rem rounded-full bg-gray-700" />
            <h4 className="text-center align-center mb-0 ml-75rem text-1.125rem">{`${body.childMarkdownRemark.timeToRead}min read`}</h4>
          </div>
          <GatsbyImage className="h-96 mb-6" image={postImage.gatsbyImageData} alt="post image" />
          <div
            className="max-w-3xl m-auto mb-2 prose prose-pre:bg-slate-50 prose-pre:text-black"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: blogData,
            }}
          />
          <Link to="/TechTalks" className="text-gray-800 text-md hover:text-primary flex items-center no-underline mt-6 rounded hover:no-underline" rel="preload">
            <div style={{ width: '22px' }} className="mr-2">
              <GatsbyImage image={childImageSharp.gatsbyImageData} imgStyle={{ width: '22px' }} style={{ verticalAlign: 'middle' }} alt="redirect arrow" />
            </div>
            Go Back
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query myBlogPost($slug: String!) {
    contentfulBlogPost(slug: {eq: $slug}) {
      postAuthor
      title
      slug
      postAuthorImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, width: 40, height: 40)
      }
      postImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
    },
    file(relativePath: {eq: "left-arrow.png"}) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default TechTalks;
